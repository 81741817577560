<template>
  <b-overlay :show="loading">
    <div v-if="isApproved">
      <div>
        <section>
          <div class=" mt-md-5">
<!--            <div class="bg-twitter banner rounded-lg d-flex align-items-center justify-content-center">-->
<!--              &lt;!&ndash;banner&ndash;&gt;-->
<!--              <div>-->
<!--                <div class="">-->
<!--                  <h3 class="text-white">-->
<!--                    Banner here-->
<!--                  </h3>-->
<!--                </div>-->
<!--              </div>-->


<!--            </div>-->

            <div class="mt-5 mb-3">
              <!-- services-->
              <div>
                <b-row>
                  <b-col class="mb-2" cols="12" md="8">
                    <select-loan-product :home-screen="true" />
                  <!--  loans-->
                    <AppliedLoans />
                  </b-col>

                  <b-col cols="12" md="4">
<!--                    Announcements-->


<!--                    <section class="mb-5">-->
<!--                      <div class="mb-2 mt-2">-->
<!--                        <h2>Announcement</h2>-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        <b-card no-body class="card-body mb-50 shadow rounded-lg">-->
<!--                          <div>-->
<!--                            <h5>-->
<!--                              Your May payment is overdue-->
<!--                            </h5>-->
<!--                          </div>-->
<!--                          <div>-->
<!--                            <div class="d-flex align-items-center justify-content-between">-->
<!--                              <small>1/06/2022</small>-->
<!--                              <small>09:21am</small>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </b-card>-->
<!--                        <b-card no-body class="card-body mb-50 shadow rounded-lg">-->
<!--                          <div>-->
<!--                            <h5>-->
<!--                              Your May payment is overdue-->
<!--                            </h5>-->
<!--                          </div>-->
<!--                          <div>-->
<!--                            <div class="d-flex align-items-center justify-content-between">-->
<!--                              <small>1/06/2022</small>-->
<!--                              <small>09:21am</small>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </b-card>-->
<!--                      </div>-->
<!--                    </section>-->
                    <section>
                      <ClientBlogs />
                    </section>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import SelectLoanProduct from "@/pages/client/loans/new/select-loan-product/index.vue";
import { get } from "lodash"
import Buy from '@/assets/svg/Buy.svg'
import ExtraCash from "@/pages/public/svgs/extra-cash.vue";
import AppliedLoans from "@/pages/client/home/AppliedLoans.vue";
import { BOverlay, BRow, BCol, BAlert, BLink, BButton, BCard, BImg } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ClientBlogs from "./ClientBlogs.vue"

export default {
  components: {
    SelectLoanProduct,
    AppliedLoans,
    ExtraCash,
    Buy,
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BAlert,
    BButton,
    BOverlay,
    ClientBlogs
  },
  data(){
    return {
      loading: false,
      loanProducts: [],
      dashboardData: null
    }
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    isApproved() {
      if (this.userData.version === 1) return true
      return this.getValueFromSource(this.userData, 'identity_verification.status', 'pending') === 'approved';
    }
  },
  created(){
    if (this.isApproved){
      this.fetchLoanProducts()
    }
  },
  methods: {
    async fetchLoanProducts(){
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchClientGroups();
        const { data } = request.data;

        this.loanProducts = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchDashboardData(){
      try {
        this.loading = true; 

        const request = await this.useJwt().clientService.fetchDashboardData();
        const { data } = request.data;
        
        this.dashboardData = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';

  .banner {
    height: 200px;
  }

  .service-card {
    width: 170px;
    min-width: 13vw;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .-ellipsis-2 {
    overflow: hidden;
    /* text-overflow: -o-ellipsis-lastline; */
    text-overflow: ellipsis;
    /* display: block; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 2.824em;
  }

</style>
