<template>
  <b-overlay :show="loading">
    <section v-if="loan_product_summaries && loan_product_summaries.length > 0">
      <!-- Loans-->
      <div class="loans-card">
        <div class="card-body">
          <div class="mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h1>Loans</h1>
                <p>Applied to {{ loan_product_summaries.length }} products</p>
              </div>
              <div>
                <router-link :to="{ name: 'client-loans' }">
                  <b-button variant="light" class="shadow">
                    View All
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center w-100 overflow-auto">
              <b-card v-for="(loan_product_summary, index) of loan_product_summaries" :key="index" class="loan-card mr-1">
                <div>
                  <h3 class="-ellipsis-1">
                    {{ loan_product_summary.title }}
                  </h3>
                </div>
                <div>
                  <div class="d-flex align-items-center">
                    <div class="flex-fill mr-1">
                      <b-progress 
                        height="7px" 
                        :value="getPercentagePaid(loan_product_summary)" 
                        :max="1"
                      />
                    </div>
                    <div>
                      <h4 class="p-0 m-0">
                        Gh¢ {{ formatMoney(loan_product_summary.total_paid) }}
                      </h4>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </section>
  </b-overlay>
</template>

<script>
import { get, round } from "lodash";
import { BButton, BCard, BProgress, BProgressBar, BOverlay } from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "AppliedLoans",
  components: {
    BOverlay,
    BProgressBar,
    BProgress,
    BButton,
    BCard
  },
  data() {
    return {
      loading: false,
      loan_product_summaries: []
    }
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    isEzwichVerified(){
      const ezwich_verified = this.getValueFromSource(this.userData, 'meta.ezwich_verified', false);
      return ezwich_verified
    }
  },
  created() {
    this.getLoanProductsSummary()
  },
  methods: {
    getPercentagePaid(loan_product_summary) {
      const total_paid = this.getValueFromSource(loan_product_summary, 'total_paid', 0)
      const total_loan_cost = this.getValueFromSource(loan_product_summary, 'total_loan_cost', 1)
      return round((total_paid / total_loan_cost), 4) 
    },
    async getLoanProductsSummary() {
      try {
        this.loading = true;
        const response = await this.useJwt().clientService.fetchLoanProductsSummary();
        this.loan_product_summaries = response.data.data;
      } catch (error) {
        this.blogPost = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .loans-card {
    background-color: #EAF2CE;
    border-radius: 20px;
  }

  .loan-card {
    min-width: 20vw;
    @media (max-width: 768px) {
      min-width: 100%;
    }
  }

  .-ellipsis-1 {
    overflow: hidden;
    /* text-overflow: -o-ellipsis-lastline; */
    text-overflow: ellipsis;
    /* display: block; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.824em;
  }
</style>
