<template>
  <div>
    <div
        class="loan-product-card-container my-1"
        :class="{ active: isActive, disabled }"
        @click="() => !disabled && $emit('selected')"
    >
      <div class="p-1">
        <div class="d-flex align-items-center">
          <div class="mr-1">
            <b-img v-if="icon" :src="icon" class="rounded-lg" height="50" width="50" />
            <BuyIcon v-else class="product-card-icon" />
          </div>
          <div class="">
            <h5 class="product-card-title p-0 m-0">
              {{ title }}
            </h5>
            <p class="ellipse two-lines m-0 p-0">
              {{ description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BImg
} from "bootstrap-vue";

import BuyIcon from "./BuyIcon.svg";

export default {
  components: {
    BuyIcon,
    BImg
  },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    icon: { type: String, required: true },
    isActive: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false }
  },
};
</script>

<style lang="scss" scoped>
.loan-product-card-container {
  border: 1px solid #144F6A;
  border-radius: 10px;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  cursor: pointer;


}

.loan-product-card-container.active {
  background: #144F6A;
  h5 {
    color: white;
  }
}

.loan-product-card-container.disabled {
  background-color: #f8f8f8;
  cursor: not-allowed;
}

.loan-product-card-container:hover {
  background-color: #144F6A;

  h5 {
    color: white;
  }
  .product-card-icon {
    color: white;
  }
}

.ellipse {
  white-space: nowrap;
  display:inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.two-lines {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

</style>
