<template>
<b-overlay :show="loading">
  <section>
    <div class="">
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <h2>Blog Posts</h2>
        <router-link :to="{ name: 'client-blogs' }">
          <small>View All</small>
        </router-link>
      </div>
      <div>
        <div v-for="(blog, i) in blogPosts" :key="i">
          <b-card no-body class="overflow-hidden rounded-lg blog-list-wrapper">
            <b-row no-gutters>
              <b-col md="5">
                <b-card-img fluid :src="getValueFromSource(blog, 'featured_image.path', 'https://picsum.photos/400/400/?image=20')" alt="Image" class="rounded-0" />
              </b-col>
              <b-col md="7">
                <div class="card-body">
                  <div>
                    <h5 class="blog-title-truncate">
                      {{ getValueFromSource(blog, 'title') }}
                    </h5>
                  </div>
                  <div>
                    <p class="blog-content-truncate" style="line-height: 14px">
                      {{ extractTextContentFromHTML(getValueFromSource(blog, 'content')) }}
                    </p>
                    <div>
                      <router-link :to="{ name: 'client-single-blog', params: { slug: getValueFromSource(blog, 'slug')} }">
                        <h6 class="text-secondary">
                          Read More ->
                        </h6>
                      </router-link>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </div>
  </section>
</b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BPagination,
  BCardImg,
  BButton
} from 'bootstrap-vue'
import {
  kFormatter
} from '@core/utils/filter';
import {
  get
} from "lodash";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,

    BCardBody,
    BCardImg,
    BLink,
    BBadge,
    BImg,
    BPagination,
    BButton
  },
  data() {
    return {
      searchFilter: '',
      blogPosts: [],
      recentBlogPosts: [],
      totalRecords: 0,
      currentPage: 1,
      perPage: 2,
      loading: false,
    }
  },
  created() {
    this.fetchAllData();
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          page: this.currentPage
        }

        const request = await this.useJwt().sharedService.fetchBlogPosts(query);
        const {
          data
        } = request.data;
        
        this.blogPosts = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';

.blog-card {}
</style>
