<template>
  <b-overlay :show="loading">
    <section v-if="homeScreen" class="mb-2">
      <div class="mb-2">
        <h2>Services</h2>
      </div>
      <div>
        <div class="d-md-flex w-100 align-items-stretch overflow-auto p-2" style="max-width: 100%">
          <b-card
              v-for="(loanProduct, index) of loan_products"
              :key="index"
              class="rounded-lg mr-1 service-card"
              @click="chooseService(loanProduct)"
          >
            <div class="">
              <div class="text-center">
                <b-img class="rounded-lg" :src="getValueFromSource(loanProduct, 'client_group_featured_image.path')"
                       height="60" width="60"
                />
              </div>
              <div class="pt-1 text-center" :title="loanProduct.title">
                <h4 class="-ellipsis-2">
                  {{ loanProduct.title }}
                </h4>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </section>


    <section v-else
      class="w-100 min-vh-100 d-flex justify-content-center align-items-center"
    >
      <b-card class="p-md-5 my-5 loan-product-card">
        <b-card-text
          class="text-right mt-0 cursor-pointer"
          @click="$router.push({ name: 'client-home' })"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Abort</span>
        </b-card-text>
        <div class="d-flex justify-content-center align-items-center mb-3 p-2">
          <h2>Select a Loan Product</h2>
        </div>
        <div class="">
          <LoanProductCard
            v-for="loan_product of loan_products"
            :key="loan_product._id"
            :icon="getValueFromSource(loan_product, 'client_group_featured_image.path')"
            :title="loan_product.title"
            :description="loan_product.description"
            :is-active="selected_loan_product === loan_product._id"
            @selected="selected_loan_product = loan_product._id"
          />
        </div>
        <div class="d-flex justify-content-center  mt-2">
          <b-button
              block
            variant="primary"
            :disabled="!selected_loan_product"
            @click="applyForNewLoan"
          >
            Continue
          </b-button>
        </div>
      </b-card>
    </section>
  </b-overlay>
</template>

<script>
import PasswordLock from "@/assets/svg/PasswordLock.svg";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BCard, BCardText, BImg, BCardTitle, BForm, BFormGroup, BLink, BModal, BOverlay } from "bootstrap-vue";

import { get } from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import LoanProductCard from "./LoanProductCard.vue";

export default {
  name: "SelectLoanProduct",
  components: {
    PasswordLock,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCard,
    BFormGroup,
    BForm,
    BLink,
    BImg,
    BModal,
    BCardTitle,
    BCardText,

    LoanProductCard,
  },
  props: {
    homeScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      selected_loan_product: "",
      loan_products: []
    };
  },
  created() {
    this.fetchLoanProducts()
  },
  methods: {
    applyForNewLoan() {
      this.$router.push({
        name: 'client-new-loan-overview',
        params: { loan_product_id: this.selected_loan_product }
      });
    },
    chooseService(loan_product) {
      this.selected_loan_product = this.getValueFromSource(loan_product, '_id');
      this.applyForNewLoan();
    },
    async fetchLoanProducts(){
      try {
       this.loading = true

        const response = await this.useJwt().clientService.fetchLoanProducts();
        this.loan_products = this.getValueFromSource(response, "data.data");
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  },
};
</script>

<style lang="scss">
.loan-product-card {
  max-width: 45%;
}
</style>
